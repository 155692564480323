/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/purecss@2.0.6/build/base-min.css
 * - /npm/purecss@2.0.6/build/grids-min.css
 * - /npm/purecss@2.0.6/build/forms-min.css
 * - /npm/purecss@2.0.6/build/buttons-min.css
 * - /npm/purecss@2.0.6/build/grids-responsive-min.css
 * - /npm/purecss@2.0.6/build/menus-min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
